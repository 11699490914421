// src/calendarService.ts

import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, query, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';

// Function to create a new calendar
export const createCalendar = async (userId: string, name: string) => {
  try {
    const calendarRef = await addDoc(collection(db, 'calendars'), {
      name,
      ownerId: userId,
      createdAt: new Date(),
      sharedWith: [],
      events: [],
    });
    return calendarRef.id;
  } catch (error) {
    console.error("Error creating calendar:", error);
    throw error;
  }
};

// Function to get all calendars for a user
export const getUserCalendars = async (userId: string) => {
    try {
      const q = query(collection(db, 'calendars'), where('ownerId', '==', userId));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          name: data.name || 'Untitled Calendar',
          ownerId: data.ownerId || userId,
          events: data.events || {}
        };
      });
    } catch (error) {
      console.error("Error fetching user calendars:", error);
      throw error;
    }
  };

// Function to update a calendar
export const updateCalendar = async (calendarId: string, updateData: any) => {
  try {
    const calendarRef = doc(db, 'calendars', calendarId);
    await updateDoc(calendarRef, updateData);
  } catch (error) {
    console.error("Error updating calendar:", error);
    throw error;
  }
};

// Function to delete a calendar
export const deleteCalendar = async (calendarId: string) => {
  try {
    await deleteDoc(doc(db, 'calendars', calendarId));
  } catch (error) {
    console.error("Error deleting calendar:", error);
    throw error;
  }
};
