import React from 'react';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { CalendarDayPropsType } from '../../types';

const CalendarDay: React.FC<CalendarDayPropsType> = ({
  dayNumberOfMonth,
  dayNumberOfWeek, // Lisätään weekday (1-7)
  monthNumber,
  isFirstOfMonth,
  isToday,
  isPast,
  isSelected,
  onClick,
  events = [],
}) => {
  const getMonthNameInFinnish = (monthNumber: number) => {
    const date = new Date(2024, monthNumber - 1); // Aseta vuosi mihin tahansa
    const monthNamePartitive = format(date, 'MMMM', { locale: fi });
    return monthNamePartitive.charAt(0).toUpperCase() + monthNamePartitive.slice(1, -5);
  };

  // Luodaan weekday-luokka, esim. 'wd1' maanantaiksi
  const weekdayClass = `wd${dayNumberOfWeek}`;

  return (
    <button
      className={`day ${weekdayClass} ${isPast ? 'past' : ''} ${isToday ? 'today' : ''} ${isSelected ? 'selected' : ''} ${monthNumber % 2 === 0 ? 'even-month' : 'odd-month'}`}
      onClick={onClick}
    >
      <div className="day-content">
        {isFirstOfMonth && (
          <div className="monthLabel">
            {getMonthNameInFinnish(monthNumber)}
          </div>
        )}
        <div className="day-number">
          {dayNumberOfMonth}
        </div>
        <div className="event-list">
          {events.map((event, index) => (
            <div key={index} className="event">
              {event}
            </div>
          ))}
        </div>
      </div>
    </button>
  );
};

export default CalendarDay;
