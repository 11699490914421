// Header.tsx
import React, { useState } from 'react';
import './header.css';
import { createCalendar } from '../../calendarService';
import WeekdaysHeader from './WeekdaysHeader';
import Icon from '@mdi/react';
import { mdiCalendarMultipleCheck, mdiAccount, mdiBell, mdiLogout } from '@mdi/js';

type Calendar = {
  id: string;
  name: string;
  ownerId: string;
  events: { [key: string]: string[] };
};

type Event = {
  id: string;
  calendarId: string;
  date: string;
  eventName: string;
};

type HeaderProps = {
  userId: string | null;
  isLoggedIn: boolean;
  onLogin: (email: string, password: string) => void;
  onLogout: () => void;
  selectedDay: string | null;
  calendars: { [key: string]: Calendar };
  events: Event[];
  selectedCalendars: string[];
  setSelectedCalendars: React.Dispatch<React.SetStateAction<string[]>>;
};

const Header: React.FC<HeaderProps> = ({
  userId,
  isLoggedIn,
  onLogin,
  onLogout,
  selectedDay,
  calendars,
  events,
  selectedCalendars,
  setSelectedCalendars,
}) => {
  const [newCalendarName, setNewCalendarName] = useState<string>('');
  const [showCalendarList, setShowCalendarList] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const toggleNotifications = () => {
    setShowCalendarList(false);
    setShowUserOptions(false);
    setShowNotifications(!showNotifications);
  };
  const toggleCalendarList = () => {
    setShowNotifications(false);
    setShowUserOptions(false);
    setShowCalendarList(!showCalendarList);
  };
  const toggleUserOptions = () => {
    setShowNotifications(false);
    setShowCalendarList(false);
    setShowUserOptions(!showUserOptions);
  };

  const toggleCalendarSelection = (calendarId: string) => {
    setSelectedCalendars((prevSelectedCalendars) =>
      prevSelectedCalendars.includes(calendarId)
        ? prevSelectedCalendars.filter((id) => id !== calendarId)
        : [...prevSelectedCalendars, calendarId]
    );
  };

  const handleCreateCalendar = async () => {
    if (newCalendarName.trim() === '' || !userId) return;
    try {
      const newCalendarId = await createCalendar(userId, newCalendarName);
      setSelectedCalendars((prevCalendars) => [...prevCalendars, newCalendarId]);
      setNewCalendarName('');
    } catch (error) {
      console.error("Error creating calendar:", error);
    }
  };

  return (
    <div className="header">
      <div className="topRow">
        <div className="logo">GLENDR</div>
        <div className="user">
          {!isLoggedIn ? (
            <>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={() => onLogin(email, password)}>Login</button>
            </>
          ) : (
            <>
              <button
                onClick={toggleNotifications}
                className={`notifications-toggle ${showNotifications ? 'active' : ''}`}
              >
                <Icon path={mdiBell} size={1} />
              </button>
              <button
                onClick={toggleUserOptions}
                className={`user-options-toggle ${showUserOptions ? 'active' : ''}`}
              >
                <Icon path={mdiAccount} size={1} />
              </button>

              {showNotifications && (
                <ul className="notifications">
                  <li>
                    <label>
                      Viimekäyntisi jälkeen:
                    </label>
                  </li>
                  <li>
                    <label>
                      Laura päivitti tapahtumaa treffit
                    </label>
                  </li>
                </ul>
              )}
              {showUserOptions && (
                <ul className="user-options">
                  <li>
                    <label>

                      Kirjaudu ulos
                    </label>
                  </li>
                  <div>
                    <button onClick={onLogout}><Icon path={mdiLogout} size={1} /></button>
                  </div>
                </ul>
              )}
            </>
          )}
        </div>
        <div className="calendarManagement">
          <button
            onClick={toggleCalendarList}
            className={`calendar-list-toggle ${showCalendarList ? 'active' : ''}`}
          >
            <Icon path={mdiCalendarMultipleCheck} size={1} />
          </button>

          {showCalendarList && (
            <ul className="calendar-list">
              <div>
                <input
                  type="text"
                  placeholder="New Calendar Name"
                  value={newCalendarName}
                  onChange={(e) => setNewCalendarName(e.target.value)}
                />
                <button onClick={handleCreateCalendar}>+</button>
              </div>
              {Object.keys(calendars).map((calendarKey) => {
                const calendar = calendars[calendarKey];
                return (
                  <li key={calendarKey}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedCalendars.includes(calendarKey)}
                        onChange={() => toggleCalendarSelection(calendarKey)}
                      />
                      {calendar.name}
                    </label>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <WeekdaysHeader />
    </div>
  );
};

export default Header;
