// Calendar.tsx
import React from 'react';
import './calendar.css'
import {
  format,
  addDays,
  startOfWeek,
  isSameDay,
  isBefore,
  startOfDay,
  subWeeks,
  getDate,
  getDay,
  getDayOfYear,
  getMonth,
  isFirstDayOfMonth,
  isLastDayOfMonth,
} from 'date-fns';
import CalendarDay from '../Calendar/CalendarDay';
import { CalendarPropsType } from '../../types';


const Calendar: React.FC<CalendarPropsType> = ({
  selectedDay,
  onDayClick,
  calendars,
  events,
  selectedCalendars,
}) => {
  const currentDate = startOfDay(new Date());

  // Start from monday
  const startDay = startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });
  const endDay = addDays(startDay, 7 * 100);

  const calendar = [];

  let day = startDay;
  while (day <= endDay) {
    calendar.push(
      Array(7)
        .fill(0)
        .map(() => {
          const newDay = day;
          day = addDays(day, 1);
          return newDay;
        })
    );
  }

  // Function to get events for a specific day and sort them by the required rules
  const getEventsForDay = (day: Date): string[] => {
    const formattedDay = format(day, 'yyyy-MM-dd');

    // Split into untimed and timed events
    const untimedEvents = events
      .filter(event => event.date === formattedDay && selectedCalendars.includes(event.calendarId) && !event.startTime)
      .sort((a, b) => a.eventName.localeCompare(b.eventName)); // Aakkosjärjestys

    const timedEvents = events
      .filter(event => event.date === formattedDay && selectedCalendars.includes(event.calendarId) && event.startTime)
      .sort((a, b) => (a.startTime || "").localeCompare(b.startTime || "")); // Aikajärjestys

    // Merge untimed events first, then timed events
    return [...untimedEvents, ...timedEvents].map(event => event.eventName);
  };

  const handleDayClick = (day: Date) => {
    const formattedDay = format(day, 'yyyy-MM-dd');
    if (selectedDay === formattedDay) {
      onDayClick(null);
    } else {
      onDayClick(formattedDay);
    }
  };

  return (
    <div className="calendar">
      {calendar.map((week, i) => (
        <div className="week" key={i}>
          {week.map((day) => {
            const formattedDay = format(day, 'yyyy-MM-dd');
            const isToday = isSameDay(day, currentDate);
            const isSelected = selectedDay === formattedDay;
            const isPast = isBefore(startOfDay(day), currentDate);

            return (
              <CalendarDay
                key={formattedDay}
                dayNumberOfMonth={getDate(day)}
                dayNumberOfWeek={getDay(day)}
                dayNumberOfYear={getDayOfYear(day)}
                monthNumber={getMonth(day) + 1}
                isFirstOfMonth={isFirstDayOfMonth(day)}
                isLastOfMonth={isLastDayOfMonth(day)}
                isPublicHolidayInMyCountry={false}
                isToday={isToday}
                isPast={isPast}
                isSelected={isSelected}
                onClick={() => handleDayClick(day)}
                events={getEventsForDay(day)}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default Calendar;
