// src/eventService.ts
import { db } from './firebaseConfig';
import { collection, addDoc, query, where, getDocs, doc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { EventType } from './types';

// Function to add a new event to a calendar
export const addEventToCalendar = async (
  calendarId: string,
  day: string,
  eventName: string,
  startTime: string | null,
  endTime: string | null,
  duration: string | null
) => {
  try {
    const eventsRef = collection(db, 'events');
    await addDoc(eventsRef, {
      calendarId,
      date: day,
      eventName,
      startTime,  // Add start time
      endTime,    // Add end time
      duration,   // Add duration
    });
    console.log("Event added successfully");
  } catch (error) {
    console.error("Error adding event to calendar:", error);
    throw error;
  }
};

// Function to get events for a specific calendar
export const getEventsForCalendar = async (calendarId: string): Promise<EventType[]> => {
  try {
    const eventsRef = collection(db, 'events');
    const q = query(eventsRef, where('calendarId', '==', calendarId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      calendarId: doc.data().calendarId as string,
      date: doc.data().date as string,
      eventName: doc.data().eventName as string,
      startTime: doc.data().startTime || null, // Retrieve start time
      endTime: doc.data().endTime || null,     // Retrieve end time
      duration: doc.data().duration || null,   // Retrieve duration
    }));
  } catch (error) {
    console.error("Error getting events for calendar:", error);
    throw error;
  }
};

// Function to update an existing event in a calendar
export const updateEventInCalendar = async (
  eventId: string,
  calendarId: string,
  day: string,
  eventName: string,
  startTime: string | null,
  endTime: string | null,
  duration: string | null
) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, {
      calendarId,
      date: day,
      eventName,
      startTime,  // Update start time
      endTime,    // Update end time
      duration,   // Update duration
    });
    console.log("Event updated successfully");
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
};

// Function to listen for events in a specific calendar in real-time
export const listenToEventsForCalendar = (calendarId: string, callback: (events: EventType[]) => void) => {
  const eventsRef = collection(db, 'events');
  const q = query(eventsRef, where('calendarId', '==', calendarId));

  return onSnapshot(q, (querySnapshot) => {
    const events: EventType[] = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      calendarId: doc.data().calendarId as string,
      date: doc.data().date as string,
      eventName: doc.data().eventName as string,
      startTime: doc.data().startTime || null,  // Listen for start time
      endTime: doc.data().endTime || null,      // Listen for end time
      duration: doc.data().duration || null,    // Listen for duration
    }));
    callback(events);
  });
};

// Function to delete an event from a calendar
export const deleteEventFromCalendar = async (eventId: string) => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await deleteDoc(eventRef);
    console.log("Event deleted successfully");
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
};
