import React, { useState, useEffect } from 'react';
import '../EventPanel/eventPanel.css';
import { addEventToCalendar, deleteEventFromCalendar, updateEventInCalendar } from '../../eventService';
import Icon from '@mdi/react';
import { mdiClose, mdiDelete, mdiContentSave } from '@mdi/js';
import { EventType, CalendarType } from '../../types';

type EventPanelProps = {
  selectedDay: string | null;
  onClearSelection: () => void;
  onDayClick: (day: string | null) => void;
  userId: string;
  calendars: { [key: string]: CalendarType };
  events: EventType[];
  selectedCalendars: string[];
  setSelectedCalendars: React.Dispatch<React.SetStateAction<string[]>>;
};

const EventPanel: React.FC<EventPanelProps> = ({
  selectedDay,
  onClearSelection,
  onDayClick,
  userId,
  calendars,
  events,
  selectedCalendars,
  setSelectedCalendars,
}) => {
  const [newEventName, setNewEventName] = useState<string>('');
  const [selectedCalendarForEvent, setSelectedCalendarForEvent] = useState<string | null>(null);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [isEventManagerActive, setEventManagerActive] = useState<boolean>(false);
  const [sniffedStartTime, setSniffedStartTime] = useState<string | null>(null);
  const [sniffedEndTime, setSniffedEndTime] = useState<string | null>(null);
  const [sniffedDuration, setSniffedDuration] = useState<string | null>(null);

  // Set default calendar when event manager is reset or when a new event is being created
  useEffect(() => {
    if (!selectedEventId) {
      const firstCalendarId = Object.keys(calendars)[0];
      if (firstCalendarId) {
        setSelectedCalendarForEvent(firstCalendarId);
      }
    }
  }, [calendars, selectedEventId]);

  // Reset event manager when day changes
  useEffect(() => {
    resetEventManager();
  }, [selectedDay]);

  const handleAddEvent = async () => {
    if (!selectedDay || !selectedCalendarForEvent || newEventName.trim() === '') return;
    try {
      if (selectedEventId) {
        await updateEventInCalendar(selectedEventId, selectedCalendarForEvent, selectedDay, newEventName, sniffedStartTime, sniffedEndTime, sniffedDuration);
      } else {
        await addEventToCalendar(selectedCalendarForEvent, selectedDay, newEventName, sniffedStartTime, sniffedEndTime, sniffedDuration);
      }
      resetEventManager();
    } catch (error) {
      console.error("Error adding or updating event:", error);
    }
  };

  const resetEventManager = () => {
    setNewEventName('');
    setSelectedCalendarForEvent(Object.keys(calendars)[0] || null);
    setSelectedEventId(null);
    setEventManagerActive(false);
    setSniffedStartTime(null);
    setSniffedEndTime(null);
    setSniffedDuration(null);
  };

  const handleDeleteEventFromCalendar = async () => {
    if (!selectedEventId) return;
    try {
      await deleteEventFromCalendar(selectedEventId);
      resetEventManager();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleEventClick = (eventId: string, eventName: string, calendarId: string) => {
    if (selectedEventId === eventId) {
      resetEventManager(); // Close if the same event is clicked again
    } else {
      setSelectedEventId(eventId);
      setNewEventName(eventName);
      setSelectedCalendarForEvent(calendarId);
      setEventManagerActive(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setNewEventName(input);
    setEventManagerActive(true);
  
    // Updated time sniffing regex: Handles start and end times properly with or without a dash and space
    const timeRegex = /(?:^|\s)([01]?[0-9]|2[0-3])(?::([0-5][0-9])|\.([0-5][0-9])|([0-5][0-9]))?(?:\s*-\s*([01]?[0-9]|2[0-3])(?::([0-5][0-9])|\.([0-5][0-9])|([0-5][0-9]))?)?/g;
  
    const matchedTimes = input.match(timeRegex);
  
    if (matchedTimes && matchedTimes.length > 0) {
      const formatTime = (hours: string, minutes: string) => {
        const formattedMinutes = minutes || '00'; // Default to "00" if minutes are missing
        return `${hours.padStart(2, '0')}:${formattedMinutes.padStart(2, '0')}`;
      };
  
      const startMatch = matchedTimes[0].match(/([01]?[0-9]|2[0-3])(?::([0-5][0-9])|\.([0-5][0-9])|([0-5][0-9]))?/);
      if (startMatch) {
        let startHours = startMatch[1];
        let startMinutes = startMatch[2] || startMatch[3] || startMatch[4] || '00'; 
  
        // Handle 4-digit time like 1530 as 15:30
        if (startMatch[0].length === 4 && !startMatch[2] && !startMatch[3]) {
          startHours = startMatch[0].slice(0, 2);
          startMinutes = startMatch[0].slice(2);
        }
  
        const startTime = formatTime(startHours, startMinutes);
        setSniffedStartTime(startTime);
  
        // End time detection (without restriction on dash (-) or space)
        const endTimePart = input.match(/-\s*([01]?[0-9]|2[0-3])(?::([0-5][0-9])|\.([0-5][0-9])|([0-5][0-9]))?/);
        if (endTimePart) {
          const endHours = endTimePart[1];
          const endMinutes = endTimePart[2] || endTimePart[3] || endTimePart[4] || '00'; 
  
          const endTime = formatTime(endHours, endMinutes);
          const duration = calculateDuration(startTime, endTime);
          setSniffedEndTime(endTime);
          setSniffedDuration(duration);
        } else {
          setSniffedEndTime(null);
          setSniffedDuration(null);
        }
      }
    } else {
      // Reset if no valid time is found
      setSniffedStartTime(null);
      setSniffedEndTime(null);
      setSniffedDuration(null);
    }
  };
  
  
  
  
  // Function to calculate duration between two times
  const calculateDuration = (start: string, end: string): string => {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);
  
    let durationMinutes = (endHour * 60 + endMinute) - (startHour * 60 + startMinute);
    if (durationMinutes < 0) {
      durationMinutes += 24 * 60; // Handle overnight times
    }
  
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    return `${hours}h ${minutes}m`;
  };
  

  const getEventsForSelectedDay = () => {
    if (!selectedDay) return [];
  
    // Split into timed and untimed events
    const untimedEvents = events
      .filter(event => event.date === selectedDay && selectedCalendars.includes(event.calendarId) && !event.startTime)
      .sort((a, b) => a.eventName.localeCompare(b.eventName)); // Aakkosjärjestys
  
    const timedEvents = events
      .filter(event => event.date === selectedDay && selectedCalendars.includes(event.calendarId) && event.startTime)
      .sort((a, b) => (a.startTime || "").localeCompare(b.startTime || "")); // Aikajärjestys
  
    // Merge untimed events first, then timed events
    return [...untimedEvents, ...timedEvents];
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    handleAddEvent(); // Call the function to add the event
  };

  const eventsForSelectedDay = getEventsForSelectedDay();

  return (
    <div className={`eventPanel ${selectedDay ? 'expanded' : ''}`}>
      <div className='eventPanelHeader'>{selectedDay}
        <button onClick={onClearSelection} className='closeDay'>
          <Icon path={mdiClose} size={1} />
        </button>
      </div>
      <div className="eventPanelContent">
        <div className={`eventList ${eventsForSelectedDay.length === 0 ? 'empty' : ''}`}>
          {selectedDay ? (
            <>
              {eventsForSelectedDay.length > 0 ? (
                <>
                  {eventsForSelectedDay.map((event) => (
                    <div
                      className={`event ${selectedEventId === event.id ? 'selected' : ''}`}
                      key={event.id}
                      onClick={() => handleEventClick(event.id, event.eventName, event.calendarId)}
                    >
                      {event.eventName}
                    </div>
                  ))}
                </>
              ) : (
                <p>Ei tapahtumia</p>
              )}
            </>
          ) : (
            <div>No day selected</div>
          )}
        </div>

        <form onSubmit={handleFormSubmit} className={`eventManager ${isEventManagerActive ? 'active' : ''}`}>
        <select
          className='eventCalendarSelect'
          value={selectedCalendarForEvent || ''}
          onChange={(e) => setSelectedCalendarForEvent(e.target.value)}
        >
          <option value="" disabled>Valittu kalenteri</option>
          {Object.keys(calendars).map((calendarKey) => (
            <option key={calendarKey} value={calendarKey}>
              {calendars[calendarKey].name}
            </option>
          ))}
        </select>
        <input
          type="text"
          className='eventTextInput'
          placeholder=""
          value={newEventName}
          onChange={handleInputChange}
        />
        <button type="submit" className='updateEventButton' disabled={!selectedDay || !selectedCalendarForEvent || !newEventName.trim()}>
          <Icon path={mdiContentSave} size={1} />
        </button>
        {selectedEventId && (
          <button onClick={handleDeleteEventFromCalendar} className='deleteEventButton'>
            <Icon path={mdiDelete} size={1} />
          </button>
        )}
      </form>

        <div className="sniffedContainer">
        {sniffedStartTime && (
            <span className="sniffedInfo">Alkamisaika: {sniffedStartTime}</span>
        )}
        {sniffedEndTime && (
            <span className="sniffedInfo">Päättymisaika: {sniffedEndTime}</span>
       
        )}
        {sniffedDuration && (
            <span className="sniffedInfo">Kesto: {sniffedDuration}</span>
        )}
          </div>
      </div>
    </div>
  );
};

export default EventPanel;
