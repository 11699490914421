import React, { useState, useEffect } from 'react';
import Calendar from './components/Calendar/Calendar';
import Header from './components/Header/Header';
import EventPanel from './components/EventPanel/EventPanel';
import { getUserCalendars } from './calendarService';
import { listenToEventsForCalendar } from './eventService'; // Importoidaan kuuntelutoiminto
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import './global.css';
import { EventType, CalendarType } from './types';


const App: React.FC = () => {
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [calendars, setCalendars] = useState<{ [key: string]: CalendarType }>({});
  const [events, setEvents] = useState<EventType[]>([]);
  const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
  const [userId, setUserId] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setIsLoggedIn(true);
      } else {
        setUserId(null);
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUserCalendars = async () => {
      if (userId) {
        const userCalendars = await getUserCalendars(userId);
        const calendarsObj = userCalendars.reduce((acc, calendar) => {
          acc[calendar.id] = {
            id: calendar.id,
            name: calendar.name || 'Untitled Calendar',
            ownerId: calendar.ownerId || userId,
            events: calendar.events || {},
          };
          return acc;
        }, {} as { [key: string]: CalendarType });
        setCalendars(calendarsObj);
        setSelectedCalendars(Object.keys(calendarsObj));
      }
    };

    fetchUserCalendars();
  }, [userId]);

  useEffect(() => {
    const unsubscribeListeners: (() => void)[] = [];

    const subscribeToCalendars = () => {
      if (selectedCalendars.length > 0) {
        selectedCalendars.forEach((calendarId) => {
          const unsubscribe = listenToEventsForCalendar(calendarId, (updatedEvents) => {
            setEvents((prevEvents) => {
              // Poistetaan vanhat tapahtumat, jotka kuuluvat päivitettyyn kalenteriin
              const filteredEvents = prevEvents.filter(event => event.calendarId !== calendarId);
              // Lisätään päivitetyt tapahtumat
              return [...filteredEvents, ...updatedEvents];
            });
          });
          unsubscribeListeners.push(unsubscribe);
        });
      }
    };

    subscribeToCalendars();

    // Palautetaan unsubscribe-funktioiden kutsu, kun komponentti unmountataan tai riippuvuudet muuttuvat
    return () => {
      unsubscribeListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [selectedCalendars]);

  const handleDayClick = (day: string | null) => {
    console.log('selected day:', day);
    setSelectedDay(day);
  };

  const clearSelection = () => {
    setSelectedDay(null);
  };

  const handleLogin = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="app">
      <Header
        userId={userId}
        isLoggedIn={isLoggedIn}
        onLogin={handleLogin}
        onLogout={handleLogout}
        selectedDay={selectedDay}
        calendars={calendars}
        events={events}
        selectedCalendars={selectedCalendars}
        setSelectedCalendars={setSelectedCalendars}
      />
      <Calendar
        selectedDay={selectedDay}
        onDayClick={handleDayClick}
        calendars={calendars}
        events={events}
        selectedCalendars={selectedCalendars}
      />
      {userId && (
        <EventPanel
          selectedDay={selectedDay}
          onDayClick={handleDayClick}
          onClearSelection={clearSelection}
          calendars={calendars}
          events={events}
          userId={userId}
          selectedCalendars={selectedCalendars}
          setSelectedCalendars={setSelectedCalendars}
        />
      )}
    </div>
  );
};

export default App;
